import { Component } from '@angular/core';
import { IconService } from '@ess-front/shared';
import { RouterOutlet } from '@angular/router';
import { LoadingComponent } from '@app/core/loading/component/loading.component';

@Component({
  selector: 'ess-root',
  templateUrl: './app.component.html',
  imports: [RouterOutlet, LoadingComponent],
})
export class AppComponent {
  constructor(private readonly iconService: IconService) {}
}
