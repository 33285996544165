// src/presets/material/confirmdialog/index.ts
var confirmdialog_default = {
  icon: {
    size: "2rem",
    color: "{overlay.modal.color}"
  },
  content: {
    gap: "1rem"
  }
};
export { confirmdialog_default as default };
