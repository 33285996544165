import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { TranslationService } from '@ess-front/shared';
import { NotificationModule } from '@ess-front/brain-shared';
import { ErrorsModule } from '@app/core/errors/errors.module';
import { EssAngularComponentsModule } from '@ess-front/angular-components';
import { environment } from '@environment';
import { routes } from '../routs';
import { LoadingInterceptor } from '@app/core/loading/interceptor/interceptor.service';
import { AuthInterceptor } from '@ess-front/authorization';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import Material from '@primeng/themes/material';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserAnimationsModule,
      NotificationModule,
      ErrorsModule,
      EssAngularComponentsModule.forRoot(environment),
    ),
    provideRouter(
      routes,
      withPreloading(PreloadAllModules),
      withComponentInputBinding(),
      withRouterConfig({ paramsInheritanceStrategy: 'always' }),
    ),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: TranslationService,
      useValue: { getActiveLanguage: (): string => 'en' },
    },
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: Material,
        options: { darkModeSelector: false },
      },
    }),
  ],
};
