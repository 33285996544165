// src/presets/material/index.ts
import accordion from "@primeng/themes/material/accordion";
import autocomplete from "@primeng/themes/material/autocomplete";
import avatar from "@primeng/themes/material/avatar";
import badge from "@primeng/themes/material/badge";
import base from "@primeng/themes/material/base";
import blockui from "@primeng/themes/material/blockui";
import breadcrumb from "@primeng/themes/material/breadcrumb";
import button from "@primeng/themes/material/button";
import card from "@primeng/themes/material/card";
import carousel from "@primeng/themes/material/carousel";
import cascadeselect from "@primeng/themes/material/cascadeselect";
import checkbox from "@primeng/themes/material/checkbox";
import chip from "@primeng/themes/material/chip";
import colorpicker from "@primeng/themes/material/colorpicker";
import confirmdialog from "@primeng/themes/material/confirmdialog";
import confirmpopup from "@primeng/themes/material/confirmpopup";
import contextmenu from "@primeng/themes/material/contextmenu";
import datatable from "@primeng/themes/material/datatable";
import dataview from "@primeng/themes/material/dataview";
import datepicker from "@primeng/themes/material/datepicker";
import dialog from "@primeng/themes/material/dialog";
import divider from "@primeng/themes/material/divider";
import dock from "@primeng/themes/material/dock";
import drawer from "@primeng/themes/material/drawer";
import editor from "@primeng/themes/material/editor";
import fieldset from "@primeng/themes/material/fieldset";
import fileupload from "@primeng/themes/material/fileupload";
import floatlabel from "@primeng/themes/material/floatlabel";
import galleria from "@primeng/themes/material/galleria";
import iconfield from "@primeng/themes/material/iconfield";
import iftalabel from "@primeng/themes/material/iftalabel";
import image from "@primeng/themes/material/image";
import imagecompare from "@primeng/themes/material/imagecompare";
import inlinemessage from "@primeng/themes/material/inlinemessage";
import inplace from "@primeng/themes/material/inplace";
import inputchips from "@primeng/themes/material/inputchips";
import inputgroup from "@primeng/themes/material/inputgroup";
import inputnumber from "@primeng/themes/material/inputnumber";
import inputotp from "@primeng/themes/material/inputotp";
import inputtext from "@primeng/themes/material/inputtext";
import knob from "@primeng/themes/material/knob";
import listbox from "@primeng/themes/material/listbox";
import megamenu from "@primeng/themes/material/megamenu";
import menu from "@primeng/themes/material/menu";
import menubar from "@primeng/themes/material/menubar";
import message from "@primeng/themes/material/message";
import metergroup from "@primeng/themes/material/metergroup";
import multiselect from "@primeng/themes/material/multiselect";
import orderlist from "@primeng/themes/material/orderlist";
import organizationchart from "@primeng/themes/material/organizationchart";
import overlaybadge from "@primeng/themes/material/overlaybadge";
import paginator from "@primeng/themes/material/paginator";
import panel from "@primeng/themes/material/panel";
import panelmenu from "@primeng/themes/material/panelmenu";
import password from "@primeng/themes/material/password";
import picklist from "@primeng/themes/material/picklist";
import popover from "@primeng/themes/material/popover";
import progressbar from "@primeng/themes/material/progressbar";
import progressspinner from "@primeng/themes/material/progressspinner";
import radiobutton from "@primeng/themes/material/radiobutton";
import rating from "@primeng/themes/material/rating";
import ripple from "@primeng/themes/material/ripple";
import scrollpanel from "@primeng/themes/material/scrollpanel";
import select from "@primeng/themes/material/select";
import selectbutton from "@primeng/themes/material/selectbutton";
import skeleton from "@primeng/themes/material/skeleton";
import slider from "@primeng/themes/material/slider";
import speeddial from "@primeng/themes/material/speeddial";
import splitbutton from "@primeng/themes/material/splitbutton";
import splitter from "@primeng/themes/material/splitter";
import stepper from "@primeng/themes/material/stepper";
import steps from "@primeng/themes/material/steps";
import tabmenu from "@primeng/themes/material/tabmenu";
import tabs from "@primeng/themes/material/tabs";
import tabview from "@primeng/themes/material/tabview";
import tag from "@primeng/themes/material/tag";
import terminal from "@primeng/themes/material/terminal";
import textarea from "@primeng/themes/material/textarea";
import tieredmenu from "@primeng/themes/material/tieredmenu";
import timeline from "@primeng/themes/material/timeline";
import toast from "@primeng/themes/material/toast";
import togglebutton from "@primeng/themes/material/togglebutton";
import toggleswitch from "@primeng/themes/material/toggleswitch";
import toolbar from "@primeng/themes/material/toolbar";
import tooltip from "@primeng/themes/material/tooltip";
import tree from "@primeng/themes/material/tree";
import treeselect from "@primeng/themes/material/treeselect";
import treetable from "@primeng/themes/material/treetable";
import virtualscroller from "@primeng/themes/material/virtualscroller";
var material_default = {
  ...base,
  components: {
    accordion,
    autocomplete,
    avatar,
    badge,
    blockui,
    breadcrumb,
    button,
    datepicker,
    card,
    carousel,
    cascadeselect,
    checkbox,
    chip,
    colorpicker,
    confirmdialog,
    confirmpopup,
    contextmenu,
    dataview,
    datatable,
    dialog,
    divider,
    dock,
    drawer,
    editor,
    fieldset,
    fileupload,
    iftalabel,
    floatlabel,
    galleria,
    iconfield,
    image,
    imagecompare,
    inlinemessage,
    inplace,
    inputchips,
    inputgroup,
    inputnumber,
    inputotp,
    inputtext,
    knob,
    listbox,
    megamenu,
    menu,
    menubar,
    message,
    metergroup,
    multiselect,
    orderlist,
    organizationchart,
    overlaybadge,
    popover,
    paginator,
    password,
    panel,
    panelmenu,
    picklist,
    progressbar,
    progressspinner,
    radiobutton,
    rating,
    scrollpanel,
    select,
    selectbutton,
    skeleton,
    slider,
    speeddial,
    splitter,
    splitbutton,
    stepper,
    steps,
    tabmenu,
    tabs,
    tabview,
    textarea,
    tieredmenu,
    tag,
    terminal,
    timeline,
    togglebutton,
    toggleswitch,
    tree,
    treeselect,
    treetable,
    toast,
    toolbar,
    virtualscroller,
    tooltip,
    ripple
  },
  css: ({
    dt
  }) => `

    `
};
export { material_default as default };
