import { Subscription } from 'rxjs';

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { NotificationService } from '@ess-front/brain-shared';
import { AuthService } from '../../../auth/auth-service/auth.service';

@Component({
  selector: 'ess-login-widget',
  templateUrl: './login-widget.component.html',
  styleUrls: ['./login-widget.component.scss'],
  animations: [
    trigger('fade', [transition('void => *', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))])]),
  ],
  standalone: false,
})
export class LoginWidgetComponent implements OnInit {
  @Output() loggedIn = new EventEmitter<void>();
  @Output() loginFailed = new EventEmitter();

  loginForm: UntypedFormGroup;
  invalidErrorMessage: boolean;
  loginError: boolean;
  loginFormSubscription: Subscription;

  constructor(
    private readonly authService: AuthService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      keepMeLoggedIn: true,
    });

    this.loginFormSubscription = this.loginForm.valueChanges.subscribe(() => (this.loginError = false));
  }

  login(email: string, password: string, keepMeLoggedIn: boolean): void {
    this.authService.login(email, password, keepMeLoggedIn).subscribe({
      next: () => this.loggedIn.emit(),
      error: () => this.handleError(),
    });
  }

  handleError(): void {
    this.loginError = true;
    this.loginFailed.emit(null);
    this.notificationService.notifyWithComponent(`Unable to log in with provided credentials`, {
      duration: 10000,
      panelClass: 'error',
    });
  }
}
