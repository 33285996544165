// This component is just for showing a global spinner for all the app
// It listens to loadingService wich listens to an http interceptor
// Extend as needed
// @aleixsuau

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../service/loading.service';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
  selector: 'ess-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  imports: [MatProgressBar],
})
export class LoadingComponent implements OnInit, OnDestroy {
  loading: number;
  loadingSubscription: Subscription;

  constructor(private readonly loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loadingSubscription = this.loadingService.loading$.subscribe(activeCalls => {
      setTimeout(() => {
        this.loading = activeCalls;
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
