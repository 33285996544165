import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { transition, trigger, style, animate } from '@angular/animations';
import { Location } from '@angular/common';

@Component({
  selector: 'ess-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition('* => *', [
        style({
          opacity: 0,
        }),
        animate(
          '400ms 150ms ease-in-out',
          style({
            opacity: 1,
          }),
        ),
      ]),
    ]),
  ],
  standalone: false,
})
export class LoginComponent {
  loginMouseEnter: boolean;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
  ) {}

  redirectBack(): void {
    const { redirectUrl } = this.location.getState() as { [key: string]: string };
    this.router.navigateByUrl(redirectUrl || '/');
  }
}
