import { Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListItem, MatNavList } from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';
import { RouterLink, RouterOutlet } from '@angular/router';
import { UserMenuModule } from '@ess-front/authorization';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'ess-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  imports: [
    MatIcon,
    MatListItem,
    MatNavList,
    MatSidenavModule,
    MatToolbar,
    RouterLink,
    RouterOutlet,
    UserMenuModule,
    MatIconButton,
  ],
})
export class BaseComponent {}
