// src/presets/material/tooltip/index.ts
var tooltip_default = {
  root: {
    background: "{surface.600}",
    color: "{surface.0}",
    maxWidth: "12.5rem",
    gutter: "0.25rem",
    shadow: "{overlay.popover.shadow}",
    padding: "0.5rem 0.75rem",
    borderRadius: "{overlay.popover.border.radius}"
  }
};
export { tooltip_default as default };
